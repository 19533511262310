import React, { FC } from "react";

const SubscriptionConfirmation: FC = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen px-12 space-y-8 max-w-[600px] mx-auto">
      <img src="/logo512.png" alt="Curate" className="w-32" />
      <h1 className="text-2xl font-bold ">Welcome to Curate 🎉</h1>
      <p className="text-lg">
        We're excited to be working with you and can't wait to find you your
        perfect match.
        <br />
        <br />
        We'll be in touch soon over text.
      </p>
      <p className="text-sm text-left w-full">-- The Curate Team</p>
    </div>
  );
};

export default SubscriptionConfirmation;
