import React, { FC } from "react";

interface ProgressStepperProps {
  totalSteps: number;
  currentStep: number;
  className?: string;
  numbered?: boolean;
}

const ProgressStepper: FC<ProgressStepperProps> = ({
  totalSteps,
  currentStep,
  className,
  numbered = true,
}) => {
  return (
    <div className={`w-full ${className || ""}`}>
      <div className="flex justify-between mb-1">
        {Array.from({ length: totalSteps + 1 }, (_, index) => (
          <div
            key={index}
            className={`w-full h-2 ${
              index < currentStep + 1 ? "bg-[#c49f2a]" : "bg-gray-200"
            } ${index < totalSteps + 1 - 1 ? "mr-1" : ""}`}
          ></div>
        ))}
      </div>
      {numbered && (
        <div className="flex justify-between text-xs text-gray-600">
          {Array.from({ length: totalSteps + 1 }, (_, index) => (
            <div key={index} className="text-center">
              {index + 1}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProgressStepper;
