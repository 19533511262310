import React from "react";

interface MembershipOptionsProps {
  sessionId?: string | null;
}

const MembershipOptions: React.FC<MembershipOptionsProps> = ({ sessionId }) => {
  return (
    <section className="flex flex-col w-full px-8 font-lato items-center">
      <a
        href={`https://checkout.square.site/merchant/MLJGHSGB76H9G/checkout/P5TVZVYFMHFHBQF5VEYLNS5G`}
        target="_blank"
        className="w-full"
      >
        <div className="w-full items-center flex flex-col border-[1px] border-[#c49f2a] rounded-md  p-4 bg-slate-50 cursor-pointer shadow">
          <p className="font-bold text-lg">Intro Membership</p>
          <span className="inline">
            <span>$99</span>{" "}
            <span className="text-xs font-semibold">/ month</span>{" "}
            <span>(75 credits)</span>
          </span>
          <ul className="list-disc pl-4 mt-2 text-sm ">
            <li>
              Get up to <b>30</b> new matches per month
            </li>
            <li>
              Request up to <b>7</b> introductions per month
            </li>
            <li>Cancel anytime, no questions asked</li>
            <p className="italic mt-2">
              Standard processing time for your requests:
            </p>

            <li>
              Each new match request fulfilled in <b>24 hours</b>
            </li>
            <li>
              Each introduction request fulfilled within <b>7 days</b>
            </li>
          </ul>
          <button className="mt-4 bg-[#c49f2a] hover:bg-[#bd9e36] text-white font-semibold rounded-full py-2 px-6 w-full shadow-sm max-w-[300px]">
            Get Started
          </button>
        </div>
      </a>
      <div className=" w-10/12 my-4"></div>

      <a
        href={`https://checkout.square.site/merchant/MLJGHSGB76H9G/checkout/BVYYAYYJ3F7SVMSO2VNK4ZCM${
          sessionId ? `?sessionId=${sessionId}` : ""
        }`}
        target="_blank"
        className="w-full"
      >
        <div className="w-full items-center flex flex-col border-[1px] border-[#c49f2a] rounded-md  p-4 bg-slate-50 cursor-pointer shadow">
          <p className="font-bold text-lg">Standard Membership</p>
          <span className="inline">
            <span>$499</span>{" "}
            <span className="text-xs font-semibold">/ month</span>{" "}
            <span>(400 credits)</span>
          </span>
          <ul className="list-disc pl-4 mt-2 text-sm">
            <li>
              Get <b>unlimited</b> new matches each month
            </li>
            <li>
              Request up to <b>40</b> introductions per month
            </li>
            <li>
              We'll give you a <b>full refund</b> if no intros made
            </li>
            <li>Cancel anytime, no questions asked</li>
            <p className="italic mt-2 font-bold">
              We'll prioritize your match and introduction requests:
            </p>
            <li>
              Each new match request fulfilled within <b>30 minutes</b>
            </li>
            <li>
              Each introduction request fulfilled within <b>24 business hours</b>
            </li>
          </ul>
          <button className="mt-4 bg-[#c49f2a] hover:bg-[#bd9e36] text-white font-semibold rounded-full py-2 px-6 w-full shadow-sm max-w-[300px]">
            Get Started
          </button>
        </div>
      </a>
    </section>
  );
};

export default MembershipOptions;
