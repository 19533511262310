import { useState } from "react";
import addPhoneNumber from "../services/formsService";
// import { usePostHog } from "posthog-js/react";

const useForms = () => {
  const [submitPhoneNumberLoading, setSubmitPhoneNumberLoading] =
    useState(false);
  const [isPhoneNumberSubmitted, setIsPhoneNumberSubmitted] = useState(false);
  // const posthog = usePostHog();

  const submitPhoneNumber = async (phoneNumber: string) => {
    setSubmitPhoneNumberLoading(true);
    // const postHogUserId = posthog.get_distinct_id();
    const postHogUserId = "user-identifier-placeholder";
    try {
      await addPhoneNumber(phoneNumber, postHogUserId);
      setIsPhoneNumberSubmitted(true);
      // posthog.capture("phone_number_submitted", { phoneNumber, postHogUserId });
    } catch (error) {
      console.error("Error submitting phone number:", error);
      setIsPhoneNumberSubmitted(false);
    } finally {
      setSubmitPhoneNumberLoading(false);
    }
  };

  return {
    submitPhoneNumberLoading,
    isPhoneNumberSubmitted,
    submitPhoneNumber,
  };
};

export default useForms;
