import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import GoogleReviewsCard from "../../components/GoogleReviewsCard";

interface Review {
  author: string;
  rating: number;
  text: string;
  date: string; // Added date field
}

interface GoogleReviewsCarouselProps {
  reviews: Review[];
}

const GoogleReviewsCarousel: React.FC<GoogleReviewsCarouselProps> = ({
  reviews,
}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full py-8">
      <Slider {...settings}>
        {reviews.map((review, index) => (
          <div key={index} className="px-2">
            <GoogleReviewsCard
              name={review.author}
              date={review.date}
              numStars={review.rating}
              reviewText={review.text}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default GoogleReviewsCarousel;
