import React from "react";

interface StepIndicatorProps {
  stepNumber: number;
}

const StepIndicator: React.FC<StepIndicatorProps> = ({ stepNumber }) => {
  return (
    <div className="flex items-center justify-center">
      <div className="relative flex flex-col items-center">
        <div className="h-32 w-0.5 bg-gray-300"></div>
        <div className="absolute top-1/2 transform -translate-y-1/2">
          <div className="w-8 h-8 rounded-full bg-[#c49f2a] flex items-center justify-center">
            <span className="text-white font-bold">{stepNumber}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepIndicator;
