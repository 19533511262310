import React, { FC, useEffect } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useMatches } from "../../hooks/useMatches";
import { useSearchParams } from "react-router-dom";

const statusOrder = [
  "pending",
  "in progress",
  "in progress (followed up)",
  "accepted",
  "rejected",
  "stale",
];

const introductionStatusToLabel = (status: string) => {
  switch (status) {
    case "pending":
      return "Pending";
    case "in progress":
      return "Reached Out";
    case "in progress (followed up)":
      return "Followed Up";
    case "accepted":
      return "Accepted";
    case "rejected":
      return "Rejected";
    case "stale":
      return "Rejected";
  }
};

const Introductions: FC = () => {
  const { introductions, introductionsLoading, fetchIntroductions } =
    useMatches();

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");

  useEffect(() => {
    fetchIntroductions();
  }, []);

  const getProgressPercentage = (status: string) => {
    const index = statusOrder.indexOf(status);
    return ((index + 1) / statusOrder.length) * 100;
  };

  const getStatusColor = (status: string) => {
    console.log("status", status);
    switch (status) {
      case "pending":
        return "bg-yellow-200 text-yellow-600";
      case "in progress":
      case "in progress (followed up)":
        return "bg-emerald-200 text-emerald-600";
      case "accepted":
        return "bg-emerald-400 text-emerald-800";
      case "rejected":
        return "bg-red-200 text-red-600";
      case "stale":
        return "bg-gray-200 text-gray-600";
      default:
        return "bg-gray-200 text-gray-600";
    }
  };

  return (
    <div className="flex flex-col items-center max-w-screen w-full">
      <div className="bg-[#f4f1ec] flex flex-col items-center w-screen min-h-screen max-w-[400px]">
        <div className="flex flex-row w-full bg-[#f4f1ec] justify-between items-center p-4 ">
          <a
            href="/"
            className="text-2xl font-bold font-playfair text-gray-400 z-25"
          >
            Curate
          </a>
          {/* <h3 className="text-xl font-semibold font-playfair italic">
            Introductions
          </h3> */}
          <a
            href="mailto:support@curate.date"
            className="text-md font-lato text-gray-400 underline"
          >
            support
          </a>
        </div>
        {/* <a
          href="/account"
          className="text-blue-500 hover:underline font-lato text-sm mb-4 self-start px-4"
        >
          &larr; Back to Account
        </a> */}
        <a
          href={`/account/client-profile?sessionId=${sessionId}`}
          className="text-blue-500 hover:underline font-lato text-sm self-start px-4 underline pb-4"
        >
          My bio (use for introductions)
        </a>

        <section className="flex flex-col items-center w-full space-y-4 px-4">
          {introductionsLoading ? (
            <div className="text-gray-500 text-center items-center h-[500px] flex flex-col justify-center">
              Loading introductions...
            </div>
          ) : introductions.length === 0 ? (
            <div className="text-gray-500 text-center items-center h-[500px] flex flex-col justify-center ">
              No introductions yet. <br /> Ask your matchmaker for an
              introduction
            </div>
          ) : (
            introductions.map((intro) => (
              <div
                key={intro.id}
                className="w-full bg-white rounded-lg shadow-md p-4"
              >
                <div className="flex justify-between items-center mb-2">
                  <h4 className="text-lg font-semibold">
                    {intro.prospect_name}
                  </h4>
                  <a
                    href={`/profile?matchId=${intro.match_id}`}
                    className="text-blue-500 hover:underline"
                    target="_blank"
                  >
                    View Match <FaChevronRight className="inline" />
                  </a>
                </div>
                <div className="relative pt-1">
                  <div className="flex mb-2 items-center justify-between">
                    <div>
                      <span
                        className={`text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full ${getStatusColor(
                          intro.status
                        )}`}
                      >
                        {introductionStatusToLabel(intro.status)}
                      </span>
                    </div>
                    <div className="text-xs text-gray-500">
                      {intro.last_updated.toLocaleDateString(undefined, {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                      })}
                    </div>
                  </div>
                  <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-200">
                    <div
                      style={{
                        width: `${getProgressPercentage(intro.status)}%`,
                      }}
                      className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${
                        getStatusColor(intro.status).split(" ")[0]
                      } animate-progress-stripe bg-stripe`}
                    ></div>
                  </div>
                </div>
              </div>
            ))
          )}
        </section>
      </div>
    </div>
  );
};

export default Introductions;
