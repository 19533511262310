import React, { useEffect } from "react";
import ProgressStepper from "../../components/UICore/ProgressStepper";
import { useState } from "react";
import Card from "../../components/UICore/Card";
import useFitFinder from "../../hooks/useFitFinder";
import LoadingSpinbox from "../../components/UICore/LoadingSpinbox";
import Div100vh from "react-div-100vh";

const FitFinder: React.FC = () => {
  const {
    image1Id,
    image2Id,
    image1Base64,
    image2Base64,
    isFinished,
    currentStep,
    handleImageClick,
    image1MostRcentlyClicked,
    TOTAL_STEPS,
    isLoading,
    preferencesString,
  } = useFitFinder();

  const [showGetStarted, setShowGetStarted] = useState(true);

  if (image1Id && image2Id && !isFinished && !showGetStarted) {
    // play the game!
    return (
      <div className="bg-[#f4f1ec] flex flex-col items-center max-w-screen   overflow-hidden">
        <Div100vh className="flex flex-col w-full max-w-[400px] ">
          <div
            className={`relative h-1/2 w-full items-center justify-center flex ${
              "" // isLoading ? "opacity-70" : ""
            }`}
          >
            <img
              src={`data:image/jpeg;base64,${image1Base64}`}
              className="w-full h-full object-cover border-b border-slate-300 shadow-md cursor-pointer"
              alt="Image 1"
              onClick={() => handleImageClick(image1Id)}
            />
            {isLoading && image1MostRcentlyClicked && (
              <div className="absolute inset-0 border-4 z-10 border-green-500 bg-green-600 opacity-50  pointer-events-none"></div>
            )}
          </div>
          <div
            className={`relative h-1/2 w-full items-center justify-center flex ${
              "" // isLoading ? "opacity-70" : ""
            }`}
          >
            <img
              src={`data:image/jpeg;base64,${image2Base64}`}
              className="w-full h-full object-cover border-t border-slate-300 shadow-md cursor-pointer border-2 "
              alt="Image 2"
              onClick={() => handleImageClick(image2Id)}
            />
            {isLoading && !image1MostRcentlyClicked && (
              <div className="absolute inset-0 bg-green-500 opacity-20  pointer-events-none"></div>
            )}
          </div>
        </Div100vh>
        <p className="absolute text-2xl font-bold font-playfair text-slate-600  z-50 top-0 left-0 p-4">
          Curate
        </p>

        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex-col items-center w-full px-4 max-w-[400px]">
          <ProgressStepper
            totalSteps={TOTAL_STEPS}
            currentStep={currentStep}
            className="mb-4"
            numbered={false}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#f4f1ec] flex flex-col items-center max-w-screen flex-grow min-h-screen  overflow-hidden md:h-screen h-screen">
      {(showGetStarted || isFinished) && (
        <div className="flex flex-row w-full bg-[#f4f1ec] justify-between items-center p-4">
          <a
            href="/"
            className="text-2xl font-bold font-playfair text-gray-400  z-50 "
          >
            Curate
          </a>
          <a
            href="/profile?id=Md3mVl05V4"
            className="text-md font-lato text-gray-400 underline"
          >
            support
          </a>
        </div>
      )}
      <section className="flex flex-col items-center w-full max-w-[400px]">
        {showGetStarted ? (
          <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)] px-4 text-center space-y-2">
            <p className="text-3xl font-bold font-playfair ">Choose one...</p>
            <p className="p-2 font-lato">
              Help us understand your preferences by tapping on the profile you
              prefer more by appearance.
            </p>
            <p className="p-2 font-lato text-rose-700">
              No screenshots or sharing to protect the privacy of our members.
            </p>
            <button
              className="bg-emerald-700 text-white px-4 py-2 rounded-lg font-semibold"
              onClick={() => setShowGetStarted(false)}
            >
              Start
            </button>
          </div>
        ) : (
          <>
            {isFinished && (
              <>
                {preferencesString ? (
                  <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)] px-4 text-center">
                    <p className="text-3xl font-bold font-playfair mb-4">
                      Completed 🎉
                    </p>
                    <span className="p-2 mb-4 inline font-lato">
                      "Among many things it looks like you tend to like people
                      who are <b>{preferencesString}</b>. I'll use that info to
                      help find the perfect match :)"
                    </span>

                    <br />
                    <p className="">Not your type?</p>
                    <br />
                    <button
                      className="bg-emerald-700 text-white px-4 py-2 rounded-lg font-semibold"
                      onClick={() => window.location.reload()}
                    >
                      Keep playing
                    </button>
                    <br />
                    <p>Or, just text me your preference.</p>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center h-[calc(100vh-200px)] px-4 text-center">
                    <LoadingSpinbox />
                  </div>
                )}
              </>
            )}

            {!image1Id && !image2Id && (
              <div className="flex flex-col items-center justify-center w-full ">
                <p className="font-playfair text-lg font-bold">Indicate One</p>
                <Card className="m-2 w-10/12 h-72 flex items-center justify-center">
                  <LoadingSpinbox />
                </Card>
                <Card className="m-2 w-10/12 h-72 flex items-center justify-center">
                  <LoadingSpinbox />
                </Card>
              </div>
            )}
          </>
        )}
      </section>

      <footer className="text-center text-xs text-gray-400 space-y-1 py-3  border-t absolute bottom-0 w-full">
        <p>curate.date 2024</p>
      </footer>
    </div>
  );
};

export default FitFinder;
