import React, { FC } from "react";
import Div100vh from "react-div-100vh";
import { useMatches } from "../../hooks/useMatches";
// import BillingSheet from "./billing-sheet";

import { Sheet } from "react-modal-sheet";
import fPreview from "../../assets/media/f-preview.png";
import mPreview from "../../assets/media/m-preview.png";
import { usePostHog } from "posthog-js/react";
import BillingSheet from "../matches/billing-sheet";
import Modal from "../../components/Modal";
import { useSearchParams } from "react-router-dom";
import { TbCoins } from "react-icons/tb";

const ProfilePage: FC = () => {
  const posthog = usePostHog();
  const [showCreditBalanceModal, setShowCreditBalanceModal] =
    React.useState(false);
  const [showBilling, setShowBilling] = React.useState(false);
  const [showRequestModal, setShowRequestModal] = React.useState(false);
  const [nextMatchFeedback, setNextMatchFeedback] = React.useState("");
  const [showIntroRequestModal, setShowIntroRequestModal] =
    React.useState(false);
  const [showIntroRequestModalText, setShowIntroRequestModalText] =
    React.useState("Loading...");

  React.useEffect(() => {
    posthog.capture("matches_page_opened");
  }, []);
  const {
    creditBalance,
    billingCycleStartDay,
    matches,
    isLoading,
    error,
    matchId,
    targetGender,
    requestAnotherMatchLoading,
    requestAnotherMatch,
    requestAnotherMatchSuccess,
    requestIntroduction,
    requestIntroError,
  } = useMatches();
  // TODO: get sessionId from hook
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    posthog?.capture("matches_page_opened");
  }, []);

  const handleRequestAnotherMatch = async () => {
    setShowRequestModal(true);
    posthog?.capture("request_another_match_clicked", { success: true });
  };

  const handleGetIntroduced = async (user_is_paying: boolean) => {
    const mId = searchParams.get("matchId");
    if (user_is_paying) {
      if (!mId) {
        alert("No match ID available");
        return;
      }
      setShowIntroRequestModal(true);
      const success = await requestIntroduction(mId);
      if (success) {
        setShowIntroRequestModalText(
          "Your matchmaker has been alerted and is working on introducing you!"
        );
      } else {
        if (requestIntroError === "Not enough credits") {
          setShowIntroRequestModalText(
            "You do not have enough credits to request an introduction."
          );
        } else {
          setShowIntroRequestModalText(
            "Failed to request introduction. Please try again later or contact support."
          );
        }
      }
    } else {
      posthog?.capture("billing_button_clicked");
      setShowBilling(true);
    }
  };

  const renderRenewalString = (dayOfMonth: number | null) => {
    if (!dayOfMonth) {
      return "unknown";
    }
    const date = new Date();
    const currentDayOfMonth = date.getDate();
    if (currentDayOfMonth > dayOfMonth) {
      date.setMonth(date.getMonth() + 1);
    }
    date.setDate(dayOfMonth);
    return date.toLocaleDateString("en-US", { month: "long", day: "numeric" });
  };

  if (isLoading) {
    return (
      <Div100vh className="bg-[#f4f1ec] flex flex-col items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        <p className="mt-4 text-xl font-lato">Loading your match...</p>
      </Div100vh>
    );
  }

  if (error) {
    return (
      <Div100vh className="bg-[#f4f1ec] flex flex-col items-center justify-center">
        <p className="text-xl  text-red-600">{error}</p>
        <p className="text-xl font-lato text-center p4">
          Please contact support at
          <br />
          <a href="tel:+12122875646" className="text-blue-500">
            +1 (212)-287-5646
          </a>
          <br />
          or email
          <br />
          <a href="mailto:support@curate.date" className="text-blue-500">
            support@curate.date
          </a>
        </p>
      </Div100vh>
    );
  }

  const currentProfile = matches[0];

  return (
    <div className="flex flex-col items-center max-w-screen w-full">
      <div
        className={`bg-[#f4f1ec] flex flex-col items-center w-screen min-h-screen max-w-[400px]`}
      >
        {/* Header */}
        <div className="flex flex-row w-full bg-[#f4f1ec] justify-between items-center p-4">
          <a
            href="/"
            className="text-2xl font-bold font-playfair text-gray-400 z-25"
          >
            Curate
          </a>
          <h3 className="text-xl font-semibold font-playfair italic">
            Matches
          </h3>
          {
            <div
              onClick={() => setShowCreditBalanceModal(true)}
              className="cursor-pointer flex flex-row items-center text-slate-500 underline"
            >
              {creditBalance} <TbCoins className="ml-2" />
            </div>
          }
        </div>

        <section className="flex flex-col items-center px-4 space-y-4 mb-[150px]">
          {currentProfile ? (
            <div className="w-full items-center flex flex-col border-[.5px] border-slate-600 rounded-md shadow-sm">
              <img
                src={`data:image/jpeg;base64,${currentProfile.prospect_image}`}
                className="w-full rounded-t-md object-cover max-h-[400px]"
                alt={currentProfile.name}
              />
              <div className="py-2 px-4 font-lato">
                <span>
                  <h4 className="text-center font-semibold text-lg text-slate-500 inline">
                    {currentProfile.name}
                  </h4>
                  <p className="text-slate-500 inline">
                    {" "}
                    | {currentProfile.age} years old | {currentProfile.location}
                  </p>
                </span>
                <p>{currentProfile.bio || currentProfile.description}</p>
                <p>{currentProfile.fit_description}</p>
              </div>
            </div>
          ) : (
            <div className="w-full items-center flex flex-col border-[.5px] border-slate-600 rounded-md shadow-sm p-4  py-6 *:space-y-2 font-lato">
              {targetGender === "female" ? (
                <img src={fPreview} alt="Female preview" className="w-11/12 ml-4" />
              ) : (
                <img src={mPreview} alt="Male preview" className="w-11/12 ml-4" />
              )}
              <p className="font-semibold text-lg">See more profiles...</p>
              <p className="text-center">
                Join Curate to see more profiles, get introduced, and discover
                your match amongst thousands of vetted members.
                <br />
                <br />
                We'll find you highly relevant people each week based on your
                specific preferences, and can reach out to them to make curated,
                high-touch introductions to you.
              </p>
              <br />
              <p className="font-semibold text-lg">Have Questions? </p>
              <p>Text our team</p>
              <a href="sms:650-223-5841" className="text-blue-500">
                +(650) 223-5841
              </a>{" "}
            </div>
          )}
        </section>

        {!showBilling && (
          <section>
            <div className="animate-gentle-bounce flex flex-col fixed bottom-2 left-2 right-2 space-y-3 items-center">
              {!showRequestModal && (
                <div
                  className="rounded-full border-[#bd9e36] border h-[55px] flex flex-col items-center justify-center p-4 shadow-lg  z-40 w-11/12 bg-white"
                  onClick={handleRequestAnotherMatch}
                >
                  <p className="text-[#c49f2a] font-semibold text-lg font-lato flex flex-row items-center">
                    <span className="w-[200px] text-center ">
                      Request Another Match{" "}
                    </span>
                    <span className=" font-normal text-lg font-lato flex flex-row items-center ml-4">
                      0 <TbCoins className="ml-2" />
                    </span>
                  </p>
                </div>
              )}
              {currentProfile.intro_tracking_link ? (
                <a
                  href={currentProfile.intro_tracking_link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="rounded-full border-[#bd9e36] bg-[#c49f2a] mx-4 h-[55px]  flex flex-col items-center justify-center p-4 shadow-lg z-40 w-11/12"
                >
                  <p className="text-white font-semibold text-lg font-lato">
                    Track Introduction
                  </p>
                </a>
              ) : (
                <div
                  className="rounded-full border-[#bd9e36] bg-[#c49f2a] mx-4 h-[55px]  flex flex-col items-center justify-center p-4 shadow-lg z-40 w-11/12"
                  onClick={() => {
                    handleGetIntroduced(
                      currentProfile.client_is_paying || false
                    );
                  }}
                >
                  <p className="text-white font-semibold text-lg font-lato flex flex-row items-center">
                    <span className="w-[200px] text-center ">
                      Get Introduced{" "}
                    </span>
                    <span className="text-white font-normal text-lg font-lato flex flex-row items-center ml-4">
                      10 <TbCoins className="ml-2" />
                    </span>
                  </p>
                </div>
              )}
            </div>
          </section>
        )}
      </div>
      <Sheet isOpen={showBilling} onClose={() => setShowBilling(false)}>
        <Sheet.Container>
          <Sheet.Header className="bg-[#f4f1ec] pb-4" />
          <Sheet.Scroller>
            <BillingSheet
              onClose={() => setShowBilling(false)}
              sessionId={matchId}
            />
          </Sheet.Scroller>
        </Sheet.Container>
      </Sheet>
      <>
        <Modal
          isOpen={showIntroRequestModal}
          onClose={() => setShowIntroRequestModal(false)}
          title="Introduction Requested"
          onConfirm={() => {
            setShowIntroRequestModal(false);
          }}
          onConfirmText="Close"
        >
          <div
            className={`p-4 text-emerald-700 font-semibold text-lg font-lato items-center justify-center w-[300px] max-w-10/12 ${
              requestIntroError ? "text-red-600" : "text-emerald-700"
            }`}
          >
            {showIntroRequestModalText === "Loading..." ? (
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900 ml-auto mr-auto "></div>
            ) : requestIntroError ? (
              <p className="text-red-600">{requestIntroError}</p>
            ) : (
              showIntroRequestModalText
            )}
          </div>
        </Modal>

        <Modal
          isOpen={showCreditBalanceModal}
          onClose={() => setShowCreditBalanceModal(false)}
          title="Credit Balance"
          // onConfirm={() => setShowCreditBalanceModal(false)}
          // onConfirmText="Close"
        >
          <div className="p-4 w-[300px] max-w-10/12">
            <p className="text-lg mb-4">
              Your current credit balance{" "}
              <span className="font-semibold">{creditBalance}</span>
              <TbCoins className="inline ml-2" />
            </p>
            <p className="text-slate-600 mb-4">
              Next reload {renderRenewalString(billingCycleStartDay)}
            </p>
            <button
              className="bg-[#c49f2a] text-white font-semibold py-2 px-4 rounded hover:bg-[#bd9e36] transition duration-300"
              onClick={() => {
                setShowCreditBalanceModal(false);
                setShowBilling(true);
              }}
            >
              Need more credits?
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={showRequestModal}
          onClose={() => setShowRequestModal(false)}
          title="Request Another Match"
          onConfirm={() => {
            requestAnotherMatch(nextMatchFeedback);
            setNextMatchFeedback("");
            // posthog?.capture("request_another_match_clicked", { success: true });
          }}
          onConfirmText="Request Another Match"
        >
          <div className="p-4 w-[300px] max-w-10/12">
            {requestAnotherMatchLoading ? (
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900 ml-auto mr-auto "></div>
            ) : requestAnotherMatchSuccess === null ? (
              <div className="flex flex-col space-y-4 text-left">
                <p>Feedback for your next match (optional)</p>
                <textarea
                  className="w-full h-24 border border-gray-300 rounded-md p-2"
                  value={nextMatchFeedback}
                  onChange={(e) => setNextMatchFeedback(e.target.value)}
                />
              </div>
            ) : requestAnotherMatchSuccess ? (
              <p className="text-lg text-emerald-700">
                Your matchmaker has been alerted and is working on finding you
                another match!
              </p>
            ) : (
              <p className="text-lg text-red-600">
                Failed to request another match. Please try again later or
                contact support.
              </p>
            )}
          </div>
        </Modal>
      </>
    </div>
  );
};

export default ProfilePage;
