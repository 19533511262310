export const googleReviews = [
  {
    author: "Alyssa S.",
    rating: 5,
    text: "As a working professional, I never really had time for dating apps. Curate has completely changed my dating life! I love having a matchmaker to the heavy lifting.",
    date: "2023-05-15",
  },
  {
    author: "Sam K.",
    rating: 5,
    text: "At 1/10th the cost of other matchmakers, I'm so happy with the value I've gotten. Would 100% recommend.",
    date: "2024-02-02",
  },
  {
    author: "Claire",
    rating: 5,
    text: "The best dating experience I've ever had. I'm so grateful to have met someone I'm so compatible with.",
    date: "2023-12-28",
  },
  {
    author: "Emily",
    rating: 5,
    text: "I love how Curate focuses on quality over quantity. Each match feels carefully considered and relevant.",
    date: "2023-05-20",
  },
];
