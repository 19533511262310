import React, { FC } from "react";
import team from "../../assets/media/full-team.png";

const About: FC = () => {
  return (
    <div className="bg-[#f4f1ec] flex flex-col items-center max-w-screen flex-grow min-h-screen overflow-hidden md:h-screen pb-4 space-y-4">
      {/* <div className="flex flex-col h-screen items-center"> */}
      <div className="flex flex-row w-full bg-[#f4f1ec] justify-between items-center p-4 ">
        <a href="/" className="text-2xl font-bold font-playfair text-gray-400">
          Curate
        </a>
        <h3 className="text-xl font-semibold font-playfair italic">FAQs</h3>
        <a href="" className="text-lg font-lato text-gray-400 underline">
          support
        </a>
      </div>
      <div className="flex flex-col justify-center items-center w-10/12 ">
        <img src={team} alt="f-preview" className="w-[500px]" />
        <p className="font-lato text-md text-slate-500 py-2">
          Hello 👋 from the Curate team
        </p>
      </div>
      <div className="flex flex-col px-8 font-lato space-y-6">
        <div>
          <h3 className="text-lg font-semibold font-playfair py-1">
            How do matches work?
          </h3>
          <p>
            Every Sunday we send you a link to 20 or more prospects we think
            you're very compatible with based off information you've given us.
            <br />
            <br />
            If you like any of these profiles, we reach out to them personally
            offering an introduction to you. If both members opt-in, we'll put
            you on a IG/iMessage thread together along with a curated
            introduction explaining why we think you're a good fit.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold font-playfair py-1">
            How do we find your matches?
          </h3>
          <p>
            We have two sources of matches
            <br />
            <br />
            <span className="font-bold"> Other Members </span>
            <br />
            These are people we've invited to our platform and have gone through
            our rigorous onboarding process
            <br />
            <br />
            <span className="font-bold"> Matchmaker Network </span>
            <br />
            Sometimes, you want to expand your reach. We keep track of 47k+ (and
            growing!) prospects via our 23 matchmakers who are on all the top
            dating platforms. Of all the best profiles in your area, if we see
            someone on a platform that we think might be a good fit for you,
            we'll reach out to them personally and introduce you.
          </p>
        </div>
        <div>
          <h3 className="text-lg font-semibold font-playfair py-1">
            How does the full refund policy work?
          </h3>
          <p>
            While we are confident we'll find you a great match, if you do not
            like any of the prospects we've sent you or a successful
            introduction is not made, we'll give you a full refund after 30
            days.
          </p>
        </div>
        <section>
          <a href="/membership" target="_blank">
            <div className="rounded-full border-[#bd9e36] bg-[#c49f2a] fixed bottom-2 left-2 right-2 mx-4 h-[75px] flex flex-col items-center justify-center p-4 shadow-lg animate-gentle-bounce z-50">
              <p className="text-white font-semibold text-xl font-lato">
                Become a Member
              </p>
            </div>
          </a>
        </section>
        <footer className="flex flex-col justify-between items-center w-full bg-[#f4f1ec] p-4 font-lato">
          <p className="font-lato text-md text-slate-400">
            © 2024 Curate. All rights reserved.
          </p>
          <a
            href="mailto:support@curate.date"
            className="font-lato text-md text-slate-400"
          >
            San Francisco, CA | support@curate.date
          </a>
        </footer>
      </div>
    </div>
  );
};

export default About;
