import React, { FC } from "react";
import MembershipOptions from "../../components/MembershipOptions";

const Membership: FC = () => {
  return (
    <div className="bg-[#f4f1ec] flex flex-col items-center max-w-screen flex-grow min-h-screen overflow-hidden md:h-screen pb-4 space-y-4">
      {/* <div className="flex flex-col h-screen items-center"> */}
      <div className="flex flex-row w-full bg-[#f4f1ec] justify-between items-center p-4 ">
        <a href="/" className="text-2xl font-bold font-playfair text-gray-400">
          Curate
        </a>
        <h3 className="text-xl font-semibold font-playfair italic">
          Membership
        </h3>
        <a
          href="/profile?id=Md3mVl05V4"
          className="text-lg font-lato text-gray-400 underline"
        >
          support
        </a>
      </div>
      <section>
        <div className="flex flex-col justify-center items-center px-4 ">
          <p className="italic text-center font-lato px-4">
            Get personalized, high-quality introductions to vetted members each
            week
          </p>
          <a href="/" className=" my-2 mb-4 underline cursor-pointer">
            How it works
          </a>

          <MembershipOptions />
        </div>
      </section>
      <footer className="flex flex-col justify-between items-center w-full bg-[#f4f1ec] p-4 font-lato">
        <p className="font-lato text-md text-slate-400">
          © 2024 Curate. All rights reserved.
        </p>
        <a
          href="mailto:support@curate.date"
          className="font-lato text-md text-slate-400"
        >
          San Francisco, CA | support@curate.date
        </a>
      </footer>
    </div>
  );
};

export default Membership;
