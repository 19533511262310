import React, { FC, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";

const AccountSettings: FC = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState("Active");

  const handleCancelSubscription = () => {
    // Implement subscription cancellation logic here
    console.log("Subscription cancelled");
  };

  const handleDeleteAccount = () => {
    // Implement account deletion logic here
    console.log("Account deleted");
  };

  return (
    <div className="flex flex-col items-center max-w-screen w-full">
      <div className="bg-[#f4f1ec] flex flex-col items-center w-screen min-h-screen max-w-[400px]">
        {/* Header */}
        <div className="flex flex-row w-full bg-[#f4f1ec] justify-between items-center p-4">
          <a
            href="/"
            className="text-2xl font-bold font-playfair text-gray-400 z-25"
          >
            Curate
          </a>
          <h3 className="text-xl font-semibold font-playfair italic">
            Account Settings
          </h3>
          <a
            href="mailto:support@curate.date"
            className="text-md font-lato text-gray-400 underline"
          >
            support
          </a>
        </div>

        <section className="flex flex-col items-center w-full px-6 pb-24">
          <a
            href="/account"
            className="text-blue-500 hover:underline font-lato text-sm mb-4 self-start flex items-center"
          >
            <FaChevronLeft className="mr-1" /> Back to Account
          </a>

          <div className="w-full space-y-4">
            <div className="bg-white rounded-lg shadow-md p-4">
              <h4 className="text-lg font-semibold mb-2">
                Subscription Status
              </h4>
              <p className="text-md">{subscriptionStatus}</p>
            </div>

            <button
              onClick={handleCancelSubscription}
              className="w-full bg-yellow-500 text-white font-semibold rounded-lg py-3 hover:bg-yellow-600 transition duration-300"
            >
              Cancel Subscription
            </button>

            <button
              onClick={handleDeleteAccount}
              className="w-full bg-red-500 text-white font-semibold rounded-lg py-3 hover:bg-red-600 transition duration-300"
            >
              Delete Account
            </button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AccountSettings;
