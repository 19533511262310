import constants from "../constants";

const submitFitFinderResponse = async (
  image1Id: string,
  image2Id: string,
  preferredImage: number,
  sessionId: string
) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/fit_finder/fit_finder_submit_response`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          image1_id: image1Id,
          image2_id: image2Id,
          prefered_image: preferredImage,
          session_id: sessionId,
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to submit fit finder response");
    }
  } catch (error) {
    console.error("Error submitting fit finder response:", error);
    throw error;
  }
};

const getFirstFitFinderImages = async (sessionId: string) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/fit_finder/get_first_fit_finder_images`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          session_id: sessionId,
        }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      console.log("data", data);
      return data;
    } else {
      throw new Error("Failed to get first fit finder images");
    }
  } catch (error) {
    console.error("Error getting first fit finder images:", error);
    throw error;
  }
};

const getPreferencesString = async (sessionId: string) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/fit_finder/preferences_string/?session_id=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data.preferences_string;
    } else {
      throw new Error("Failed to get preferences string");
    }
  } catch (error) {
    console.error("Error getting preferences string:", error);
    throw error;
  }
};

const onFitFinderGameComplete = async (sessionId: string) => {
  try {
    const response = await fetch(
      `${constants.BACKEND_API_ENDPOINT}/fit_finder/on_fit_finder_game_complete?session_id=${sessionId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error("Failed to complete fit finder game");
    }
  } catch (error) {
    console.error("Error completing fit finder game:", error);
    throw error;
  }
};

export {
  submitFitFinderResponse,
  getFirstFitFinderImages,
  getPreferencesString,
  onFitFinderGameComplete,
};
