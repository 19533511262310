import React, { FC, useState, useEffect } from "react";

interface CardProps {
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
}

const Card: FC<CardProps> = ({ children, className, onClick }) => {
  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    setIsChanging(true);
    const timer = setTimeout(() => setIsChanging(false), 300); // 300ms matches the transition duration
    return () => clearTimeout(timer);
  }, [children]); // Assuming the image is a child of the Card

  return (
    <div
      className={`bg-white shadow  border  border-slate-200 rounded-lg p-4 ${
        className || ""
      } ${isChanging ? "image-changing" : ""}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Card;
