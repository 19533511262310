import React, { FC } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

interface ExpandableProps {
  previewText: string;
  expandedContent: string;
  className?: string; // Optional className prop added
}

const Expandable: FC<ExpandableProps> = ({
  previewText,
  expandedContent,
  className,
}) => {
  const [expanded, setExpanded] = React.useState(false);

  return (
    <div
      onClick={() => setExpanded(!expanded)}
      className={`bg-white shadow  border  border-slate-200 rounded-lg p-4 flex flex-col space-y-2   cursor-pointer max-w-[450px] w-10/12 ${
        className || ""
      }`}
    >
      <div className="flex flex-row  justify-between items-center">
        <h3 className="font-roboto text-xl ml-1">{previewText}</h3>
        {expanded ? <FiChevronUp size={24} /> : <FiChevronDown size={24} />}
      </div>
      {expanded && (
        <p className="font-roboto text-lg text-slate-700">{expandedContent}</p>
      )}
    </div>
  );
};

export default Expandable;
