import React, { FC, useEffect, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import useUser from "../../hooks/useUser";
import { useSearchParams } from "react-router-dom";

const ClientProfilePage: FC = () => {
  const { userBio, userName, updateBio, updateProfilePhoto, profilePhotoUrl } =
    useUser();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("sessionId");
  const [editableBio, setEditableBio] = useState(userBio);
  const [isEditing, setIsEditing] = useState(false);

  const handleBioChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditableBio(e.target.value);
  };

  useEffect(() => {
    setEditableBio(userBio);
  }, [userBio]);

  const handleSaveBio = async () => {
    if (sessionId && editableBio) {
      await updateBio(sessionId, editableBio);
      setIsEditing(false);
    }
  };

  const handlePhotoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && sessionId) {
      await updateProfilePhoto(sessionId, file);
    }
  };

  return (
    <div className="flex flex-col items-center max-w-screen w-full">
      <div className="bg-[#f4f1ec] flex flex-col items-center w-screen min-h-screen max-w-[400px]">
        {/* Header */}
        <div className="flex flex-row w-full bg-[#f4f1ec] justify-between items-center p-4">
          <a
            href="/"
            className="text-2xl font-bold font-playfair text-gray-400 z-25"
          >
            Curate
          </a>
          <h3 className="text-xl font-semibold font-playfair italic">
            Client Profile
          </h3>
          <a
            href="mailto:support@curate.date"
            className="text-md font-lato text-gray-400 underline"
          >
            support
          </a>
        </div>

        <section className="flex flex-col items-center w-full px-6 pb-24">
          <a
            href={`/account/introductions?sessionId=${sessionId}`}
            className="text-blue-500 hover:underline font-lato text-sm mb-4 self-start flex items-center"
          >
            <FaChevronLeft className="mr-1" /> Back to Introductions
          </a>

          <div className="relative">
            {profilePhotoUrl ? (
              <img
                src={`data:image/png;base64,${profilePhotoUrl}`}
                alt="Profile"
                className="w-32 h-32 rounded-full mb-4"
              />
            ) : (
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                alt="Profile placeholder"
                className="w-32 h-32 rounded-full mb-4"
              />
            )}
            <label
              htmlFor="photo-upload"
              className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full cursor-pointer"
            >
              Edit
            </label>
            <input
              id="photo-upload"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handlePhotoUpload}
            />
          </div>
          <h1 className="text-2xl font-bold font-lato mb-6">{userName}</h1>

          <div className="w-full space-y-6">
            <ProfileSection title="Client Info">
              {isEditing ? (
                <div>
                  <textarea
                    value={editableBio ?? ""}
                    onChange={handleBioChange}
                    className="w-full p-2 border rounded min-h-[500px]"
                    rows={5}
                  />
                  <div className="flex justify-end mt-2">
                    <button
                      onClick={handleSaveBio}
                      className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setIsEditing(false)}
                      className="bg-gray-300 text-black px-4 py-2 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <InfoItem title="Bio for Introductions">
                    {editableBio}
                  </InfoItem>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="mt-2 text-blue-500 underline"
                  >
                    Edit Bio
                  </button>
                </div>
              )}
            </ProfileSection>
          </div>
        </section>
      </div>
    </div>
  );
};

const ProfileSection: FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => (
  <div className="w-full">
    <h2 className="text-lg font-bold font-lato mb-2">{title}</h2>
    <div className="bg-white rounded-lg shadow-md p-4">{children}</div>
  </div>
);

const InfoItem: FC<{ title: string; children: React.ReactNode }> = ({
  title,
  children,
}) => (
  <div className="mb-2">
    <h3 className="text-md font-bold font-lato">{title}</h3>
    <p className="text-sm font-lato">{children}</p>
  </div>
);

export default ClientProfilePage;
