import React, { FC } from "react";
import team from "../../assets/media/full-team.png";

const IntroMembership: FC = () => {
  return (
    <div className="bg-[#f4f1ec] flex flex-col items-center max-w-screen min-h-screen w-ful pb-4 space-y-4">
      {/* <div className="flex flex-col h-screen items-center"> */}
      <div className="flex flex-row w-full bg-[#f4f1ec] justify-between items-center p-4 ">
        <a href="/" className="text-2xl font-bold font-playfair text-gray-400">
          Curate
        </a>
        <h3 className="text-xl font-semibold font-playfair italic">
          Membership
        </h3>
        <a
          href="/profile?id=Md3mVl05V4"
          className="text-lg font-lato text-gray-400 underline"
        >
          support
        </a>
      </div>
      <div className="flex flex-col justify-center items-center px-4 ">
        <p className="italic text-center font-lato px-4 pb-4">
          Get personalized, high-quality introductions to vetted members
        </p>
        <section className="flex flex-col w-full px-8 font-lato items-center">
          <a
            href={`https://checkout.square.site/merchant/MLJGHSGB76H9G/checkout/P5TVZVYFMHFHBQF5VEYLNS5G`}
            target="_blank"
            className="w-full"
          >
            <div className="w-full items-center flex flex-col border-[1px] border-[#c49f2a] rounded-md  p-4 bg-slate-50 cursor-pointer shadow">
              <p className="font-bold text-lg">Intro Membership</p>
              <span className="inline">
                <span>$99</span>{" "}
                <span className="text-xs font-semibold">/ month</span>{" "}
                <span>(75 credits)</span>
              </span>
              <ul className="list-disc pl-4 mt-2 text-sm ">
                <li>
                  Get up to <b>30</b> new matches per month
                </li>
                <li>
                  Request up to <b>7</b> introductions per month
                </li>
                <li>Cancel anytime, no questions asked</li>
                <p className="italic mt-2">
                  Standard processing time for your requests:
                </p>
    
                <li>
                  Each new match request fulfilled in <b>24 hours</b>
                </li>
                <li>
                  Each introduction request fulfilled within <b>7 days</b>
                </li>
              </ul>
              <button className="mt-4 bg-[#c49f2a] hover:bg-[#bd9e36] text-white font-semibold rounded-full py-2 px-6 w-full shadow-sm max-w-[300px]">
                Get Started
              </button>
            </div>
          </a>
          <div className=" w-10/12 my-4"></div>
        </section>
        <section>
          <div className="flex flex-col px-8 font-lato space-y-6">
            <div>
              <h3 className="text-lg font-semibold font-playfair py-1">
                How do matches work?
              </h3>
              <p>
                Each week we send you a link to prospects we think you're very
                compatible with based off information you've given us.
                <br />
                <br />
                If you like the profile, we reach out to them personally
                offering an introduction to you and pitching them on why they
                should meet you. If both members opt-in, we'll put you a on
                IG/iMessage thread together along with a curated introduction
                explaining why we think you're both a good fit.
              </p>
            </div>
            <div>
              <h3 className="text-lg font-semibold font-playfair py-1">
                How do we find your matches?
              </h3>
              <p>
                We have two sources of matches. Specifically,
                <br />
                <span className="font-bold"> 1) Curate Members </span>
                <br />
                These are people we've invited to our platform and have gone
                through our rigorous onboarding process
                <br />
                <br />
                <span className="font-bold">
                  2) Extensive Matchmaker Network{" "}
                </span>
                <br />
                Sometimes, you want to expand your reach. We keep track of 147k+
                (and growing!) prospects via our 52 matchmakers who are on all
                the top dating platforms. Of all the best profiles in your area,
                if we see someone on a platform that we think might be a good
                fit for you, we'll reach out to them personally and introduce
                you.
              </p>
            </div>

            <h3 className="text-lg font-semibold font-playfair py-1">
              How does the full refund policy work?
            </h3>
            <p>
              While we are confident we'll find you a great match, on our{" "}
              <a href="/membership" className="underline">
                Standard Membership
              </a>{" "}
              and{" "}
              <a href="/membership" className="underline">
                Curate Concierge
              </a>{" "}
              plans, if you do not like any of the prospects we've sent you or
              if a successful introduction is not made, we'll give you a full
              refund after 30 days.
            </p>
            <br />

            <h3 className="text-lg font-semibold font-playfair py-1">
              About us
            </h3>
            <div className="flex flex-col justify-center items-center w-full ml-auto mr-auto">
              <img src={team} alt="f-preview" className="w-[500px]" />
              <p className="font-lato text-md text-slate-500 py-2">
                Hello 👋 from the Curate team
              </p>
              <p>
                We're a team of matchmakers out of the Bay Area. If you ever
                need help or have questions, feel free to shoot us a text{" "}
                <a
                  href="sms:650-223-5841"
                  className="underline text-blue-800 py-2"
                >
                  650-223-5841
                </a>
                .
              </p>
            </div>
          </div>
        </section>
      </div>
      <footer className="flex flex-col justify-between items-center w-full bg-[#f4f1ec] p-4 font-lato">
        <p className="font-lato text-md text-slate-400">
          © 2024 Curate. All rights reserved.
        </p>
        <a
          href="mailto:support@curate.date"
          className="font-lato text-md text-slate-400"
        >
          San Francisco, CA | support@curate.date
        </a>
      </footer>
    </div>
  );
};

export default IntroMembership;
