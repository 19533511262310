import React, { FC, useState } from "react";
import Constants from "../../constants";
import useForms from "../../hooks/useForms";
import Modal from "../../components/Modal";
import PhoneNumberInput from "../../components/UICore/PhoneNumberInput";

import { FaInstagram } from "react-icons/fa";
import { usePostHog } from "posthog-js/react";

const Menu: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const postHogUserIdentifier = usePostHog().get_distinct_id();

  const {
    submitPhoneNumberLoading,
    submitPhoneNumber,
    isPhoneNumberSubmitted,
  } = useForms();
  const openModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsModalOpen(true);
  };
  const handleNavigation = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    const section = document.getElementById(id);
    if (section) {
      const topPosition = section.offsetTop - 100; // Adjust space above the section
      window.scrollTo({ top: topPosition, behavior: "smooth" });
    }
  };

  const callToActionSection = () => {
    return (
      <a href={`${Constants.APPLICATION_LINK}?pId=${postHogUserIdentifier}`}>
        <button className="bg-[#c49f2a] hover:bg-[#bd9e36] text-white font-semibold  rounded-full py-3 px-4">
          Join Us
        </button>
      </a>
    );
  };

  return (
    <div className="z-50  flex flex-row bg-white max-w-[650px] space-x-2 w-10/12 rounded-full p-3 items-center justify-between fixed top-3 shadow-lg font-lato">
      <a
        href="/"
        onClick={(e) => handleNavigation(e, "top")}
        className="flex flex-row items-center justify-center"
      >
        <h1 className="font-playfair text-2xl pl-4 pr-2 font-semibold ">
          Curate
        </h1>
      </a>
      <a
        href="#about"
        onClick={(e) => handleNavigation(e, "about")}
        className="font-roboto hover:underline hidden md:flex"
      >
        About
      </a>
      <a
        href="#faq"
        onClick={(e) => handleNavigation(e, "faq")}
        className="font-roboto hover:underline hidden md:flex"
      >
        FAQ
      </a>
      <a
        href="#support"
        onClick={(e) => handleNavigation(e, "support")}
        className="font-roboto hover:underline hidden md:flex"
      >
        Support
      </a>
      <div className="flex flex-row items-center md:hidden">
        <a
          href="https://www.instagram.com/curate.date/"
          target="_blank"
          className="md:hidden z-100 "
        >
          <FaInstagram size={30} className="cursor-pointer mt-[.5px]" />
        </a>
        <div className="h-6 w-px bg-slate-400 mx-4"></div>

        {callToActionSection()}
      </div>
      <div className="hidden md:flex">{callToActionSection()}</div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Apply Now"
        onConfirmText="Submit"
        onConfirm={() => {
          submitPhoneNumber(phoneNumber);
          setIsModalOpen(false);
        }}
      >
        <div className="p-4">
          <p className="mb-4">Please enter your phone number to apply:</p>
          {isPhoneNumberSubmitted ? (
            <p className="text-lg text-emerald-700">
              Check your phone for a text from Sarah. She'll reach out with an
              application.
            </p>
          ) : (
            <PhoneNumberInput
              phoneNumber={phoneNumber}
              setPhoneNumber={(value) => setPhoneNumber(value)}
              placeholder="+1 (_ _ _) - _ _ _ _ - _ _ _"
              loadingAfterSubmission={submitPhoneNumberLoading}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Menu;
