import React, { FC, useState, useEffect } from "react";
import { FaChevronRight } from "react-icons/fa";

interface Match {
  id: string;
  matchName: string;
  personalized_matchmaker_note: string;
  matchLink: string;
  lastUpdated: Date;
}

const sampleMatches: Match[] = [
  {
    id: "1",
    matchName: "John Doe",
    personalized_matchmaker_note:
      "John seems to share your passion for outdoor activities and has a similar career background.",
    matchLink: "/profile?matchId=1",
    lastUpdated: new Date(),
  },
  {
    id: "2",
    matchName: "Jane Smith",
    personalized_matchmaker_note:
      "Jane's love for travel and her entrepreneurial spirit align well with your interests.",
    matchLink: "/profile?matchId=2",
    lastUpdated: new Date(),
  },
];

const ClientMatches: FC = () => {
  const [matches, setMatches] = useState<Match[]>(sampleMatches);

  useEffect(() => {
    // Fetch matches data from your API
    // This is a placeholder, replace with actual API call
    const fetchMatches = async () => {
      // const response = await fetch("/api/matches");
      // const data = await response.json();
      // setMatches(data);
    };

    fetchMatches();
  }, []);

  return (
    <div className="flex flex-col items-center max-w-screen w-full">
      <div className="bg-[#f4f1ec] flex flex-col items-center w-screen min-h-screen max-w-[400px]">
        <div className="flex flex-row w-full bg-[#f4f1ec] justify-between items-center p-4">
          <a
            href="/"
            className="text-2xl font-bold font-playfair text-gray-400 z-25"
          >
            Curate
          </a>
          <h3 className="text-xl font-semibold font-playfair italic">
            Matches
          </h3>
          <a
            href="mailto:support@curate.date"
            className="text-md font-lato text-gray-400 underline"
          >
            support
          </a>
        </div>
        <a
          href="/account"
          className="text-blue-500 hover:underline font-lato text-sm mb-4 self-start px-4"
        >
          &larr; Back to Account
        </a>

        <section className="flex flex-col items-center w-full space-y-4 px-4">
          {matches.map((match) => (
            <div
              key={match.id}
              className="w-full bg-white rounded-lg shadow-md p-4"
            >
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-lg font-semibold">{match.matchName}</h4>
                <a
                  href={match.matchLink}
                  className="text-blue-500 hover:underline"
                >
                  View Match <FaChevronRight className="inline" />
                </a>
              </div>
              <p className="text-sm text-gray-600 mb-2">
                {match.personalized_matchmaker_note}
              </p>
              <div className="text-xs text-gray-500">
                Last updated:{" "}
                {match.lastUpdated.toLocaleDateString(undefined, {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </div>
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default ClientMatches;
