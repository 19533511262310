import React, { useState, useEffect } from "react";
import { FaStar, FaRegStar } from "react-icons/fa";

interface GoogleReviewsCardProps {
  name: string;
  date: string;
  numStars: number;
  reviewText: string;
  reviewUrl?: string;
}

const GoogleReviewsCard: React.FC<GoogleReviewsCardProps> = ({
  name,
  date,
  numStars,
  reviewText,
  reviewUrl,
}) => {
  const [truncatedText, setTruncatedText] = useState(reviewText);
  const [showReadMore, setShowReadMore] = useState(false);

  useEffect(() => {
    const element = document.createElement("div");
    element.style.width = "100%";
    element.style.height = "480px";
    element.style.fontSize = "14px";
    element.innerText = reviewText;
    document.body.appendChild(element);

    if (element.scrollHeight > element.clientHeight) {
      setTruncatedText(reviewText.slice(0, 300) + "...");
      setShowReadMore(true);
    }

    document.body.removeChild(element);
  }, [reviewText]);

  return (
    <div className="bg-white rounded-lg shadow-md p-4 max-w-md mx-auto h-[320px] flex flex-col my-4">
      <div className="flex items-center mb-2">
        <div className="w-10 h-10 bg-gray-300 rounded-full flex items-center justify-center mr-3 ">
          <span className="text-xl font-bold text-white">{name.charAt(0)}</span>
        </div>
        <div>
          <h3 className="font-bold text-gray-800">{name}</h3>
          <div className="flex items-center">
            <div className="flex mr-2">
              {[...Array(5)].map((_, index) =>
                index < numStars ? (
                  <FaStar key={index} className="text-yellow-400" />
                ) : (
                  <FaRegStar key={index} className="text-gray-300" />
                )
              )}
            </div>
            <span className="text-gray-500 text-sm">{date}</span>
          </div>
        </div>
      </div>
      <p className="text-gray-600 mt-2 flex-grow overflow-hidden">
        {truncatedText}
        {showReadMore && (
          <a
            href={reviewUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline ml-1"
          >
            Read more
          </a>
        )}
      </p>
      <div className="mt-4 flex items-center">
        <img
          src="https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png"
          alt="Google Logo"
          className="h-5 mr-2"
        />
        <span className="text-sm text-gray-500">Posted on Google</span>
      </div>
    </div>
  );
};

export default GoogleReviewsCard;
