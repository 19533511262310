import constants from "../constants";

export const getClientBio = async (sessionId: string) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/client_bio?sessionId=${sessionId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data;
};

export const updateClientBio = async (sessionId: string, newBio: string) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/update_bio?sessionId=${sessionId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bio: newBio }),
    }
  );
  const data = await response.json();
  return data.bio;
};

export const updateClientProfilePhoto = async (
  sessionId: string,
  file: File
) => {
  const formData = new FormData();
  formData.append("photo", file);

  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/user/update_profile_photo?sessionId=${sessionId}`,
    {
      method: "POST",
      body: formData,
    }
  );
  const data = await response.json();
  return data;
};
